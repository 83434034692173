<template>
  <div class="download-container">
    <MainHeader />
    <CommonTop />
    <div class="section">
      <div class="download-box page-content-wrap">
          <div @click="downloadAll" class="download-btn">下载全部资料</div>
          <div class="list">
            <div v-for="(item, index) in list" :key="index" class="item">
              <div class="icon-box">
                <span class="icon iconfont icon-ziliaoku"></span>
              </div>
              <div class="name">{{item.name}}</div>
              <span @click="downloadHandle(item)" class="icon iconfont icon-xiazai download-icon"></span>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import MainHeader from '@/components/header/main.vue'
import CommonTop from '@/components/commonTop/index.vue'
const fileDownload = require('js-file-download')
import axios from 'axios'
export default {
  components: {
    MainHeader,
    CommonTop
  },
  data () {
    return {
      id: null,
      list: []
    }
  },
  created () {
    this.id = this.$route.query.id
    this.getList()
  },
  methods: {
    async getList () {
      const res = await this.$http.get('/api/goods/files', { goods_id: this.id })
      this.list = res
    },
    // 下载全部资料
    downloadAll () {
      const _this = this
      this.list.forEach(item => {
        _this.downloadHandle(item)
      })
    },
    // 下载单个资料
    downloadHandle (item) {
      axios.get(item.url, {
        responseType: 'blob',
        headers: { 'Content-Type': 'application/octet-stream' }
      }).then(res => {
        // console.log(res.data)
        fileDownload(res.data, item.name)
        // this.$store.dispatch('setUserInfo', data.data)
      }).catch(err => {
        console.log(err)
      })
      // window.open(item.url)
      // window.location.href= item.url
      // fileDownload(item.url, item.name)
      // location.href = item.url
    }
  }
}
</script>
<style scoped lang="scss">
.download-container {
  .section {
    background-color: #f5f5f5;
    padding: 20px 0;
    .download-box {
      min-height: 450px;
      .download-btn {
        width: 110px;
        height: 36px;
        background-color: #D9262C;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        cursor: pointer;
        &:hover {
          opacity: .8;
        }
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;;
        .item {
          background-color: #ffffff;
          border-radius: 2px;
          overflow: hidden;
          margin-top: 20px;
          width: 386px;
          display: flex;
          align-items: center;
          margin-right: 20px;
          padding-right: 15px;
          transition: all .2s linear;
          &:hover {
            box-shadow: 0 15px 30px rgba(0,0,0,.1);
            transform: translate3d(0,-2px,0)
          }
          // height: 68px;
          .icon-box {
            width: 68px;
            height: 68px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #f1d6d6;
            .iconfont {
              font-size: 28px;
              color: #D9262C;
            }
          }
          .name {
            flex: 1;
            text-align: justify;
            line-height: 20px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-right: 15px;
            margin-left: 15px;
          }
          &:nth-child(3n) {
            margin-right: 0;
          }
          &:nth-child(-n + 3) {
            margin-top: 0;
          }
          .download-icon {
            color: #D9262C;
            font-size: 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
